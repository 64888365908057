<template>
  <div class="content flex flex-column">
    <div class="title">
      {{ hitokoto }}
    </div>
    <div class="blocks flex flex-wrap">
      <div class="block info flex align-center justify-center">
        <div class="title">信息</div>
        <span>Hello，我是{{ info.uname }}</span>
      </div>
      <div class="block flex align-center justify-center flex-column article">
        <div class="title">文章</div>
        <div>
          <span>{{ info.artnum }}</span
          >篇
        </div>
        <div class="tips">二天前发布了一篇文章，继续努力哦！</div>
      </div>
      <div class="block comment flex align-center justify-center flex-column">
        <div class="title">评论</div>
        <div>
          <span>{{ info.comment }}</span
          >条
        </div>
        <div class="tips">感谢陌生人的留言！</div>
      </div>
      <div class="block flex align-center justify-center flex-column peopleNum">
        <div class="title">说说</div>
        <div><span>40</span>条</div>
        <div class="tips">一些闲言碎语</div>
      </div>
      <div class="block flex align-center justify-center flex-column peopleNum">
        <div class="title">图文</div>
        <div><span>46</span>篇</div>
        <div class="tips">微小而美好的故事</div>
      </div>
      <div class="block flex align-center justify-center flex-column peopleNum">
        <div class="title">友链</div>
        <div><span>50</span>条</div>
        <div class="tips">小天地</div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapState } from 'vuex'
// import { getHomeInfo } from '@/api/user'
export default {
  //   computed: mapState(['userInfo']),
  data() {
    return {
      hitokoto: "",
      info: {
        uname: "",
        artnum: "",
        comment: "",
      },
    };
  },
  created() {
    // this.setGui()
    this.getHitokoto();
    this.getUseInfo();
  },
  methods: {
    getUseInfo() {
      this.$axios
        .get("/admin/gethome")
        .then((res) => {
          console.log(res);
          console.log(res.data.data);

          if (res.data.code == 0) {
            console.log(res.data.data);
            const detail = res.data.data;
            this.info.uname = detail.result[0].name; //管理员名
            this.info.artnum = detail.result1[0]["count(*)"]; //文章数量
            this.info.comment = detail.result2[0]["count(*)"];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getHitokoto() {
      this.$axios.get("http://v3.wufazhuce.com:8000/api/onelist/6210/0"); //这里正常使用
      // 一言接口
      this.$axios.get("https://v1.hitokoto.cn/").then((data) => {
        console.log(data);
        let dataa = data.data;
        console.log(dataa);
        let str = dataa.hitokoto;
        let yiyan = str;
        this.hitokoto = yiyan;
        console.log(this.hitokoto);
        console.log(yiyan);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .title {
    color: #75ad9b;
    font-weight: bold;
    font-size: 16px;
    text-align: left;
  }
  .blocks {
    display: flex;
    flex-wrap: wrap;
    .block {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex: 1 0 30%;
      height: 300px;
      margin-right: 2%;
      margin-top: 4%;
      background-color: #ffffff;
      box-shadow: 0px 2px 19px 0px #248aff38;
      border-radius: 25px;
      position: relative;
      &:nth-of-type(0),
      &:nth-of-type(2) &:nth-of-type(3) {
        margin-bottom: 30px;
        margin-top: 30px;
      }
      // &:nth-of-type(odd) {
      //   margin-right: 2%;
      // }
      .title {
        position: absolute;
        left: 20px;
        top: 20px;
        color: #5eaa91;
        font-size: 16px;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -6px;
          width: 20px;
          height: 2px;
          background: #dbe8f7;
        }
      }
      &.info {
        color: #5eaa91;
        font-size: 18px;
      }
      &.article,
      &.comment,
      &.peopleNum {
        span {
          color: #5eaa91;
          font-size: 40px;
          font-style: oblique;
          margin-right: 6px;
        }
        .tips {
          color: #bdc6cf;
          margin-top: 40px;
        }
      }
    }
  }
}
@media screen and (max-width: 900px) {
  * {
    margin: 0px;
    padding: 0px;
  }
  .content {
    width: 100vw;
  }
  .title {
    //    color: #248cff;
    // font-weight: bold;
    // font-size: 13px;
    // text-align: center;
    //    width: 98%;
    display: none;
  }
  .blocks {
    margin-top: -40px;
    display: flex;
    flex-direction: column;
    margin-left: -60px;
    width: 75vw;

    .block {
      height: 550px;
      &:nth-of-type(odd) {
        margin-top: 20px;
        margin-bottom: 40px;
      }
    }
  }
}
</style>
