<template>
  <div>
    <div class="title flex align-center">
      <div class="flex align-center">
        <span></span>{{ id ? "编辑" : "新增" }}友链
      </div>
    </div>
    <el-form label-width="120px" @submit.native.prevent="save" class="laber">
      <el-form-item label="网站名称">
        <el-input v-model="model.name"></el-input>
      </el-form-item>
      <el-form-item label="网站地址">
        <el-input v-model="model.site"></el-input>
      </el-form-item>
      <el-form-item label="网站描述">
        <el-input v-model="model.description"></el-input>
      </el-form-item>
      <el-form-item label="网站头像">
        <el-upload
          class="avatar-uploader"
          drag
          action="http://101.43.228.111:3000/admin/upload"
          name="head_img"
          :show-file-list="false"
          :on-success="handleSuccess"
        >
          <img v-if="model.imgUrl" :src="model.imgUrl" class="avatar" />
          <i class="iconfont icon-jurassic_image" v-if="!imgUrl"></i>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" native-type="submit">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    id: {},
  },
  data() {
    return {
      model: {
        id: "",
        name: "",
        site: "",
        imgUrl: "",
        description: "",
      },
    };
  },
  methods: {
    handleSuccess(res, file) {
      console.log(res.data);
      this.model.imgUrl = res.data;
      this.$message.success("图片上传成功");
    },
    getLinkDetail() {
      this.$axios
        .get("/admin/getLinkDetail", {
          params: {
            link_id: this.$route.params.id,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            const detail = res.data.data[0];
            this.model.name = detail.name;
            this.model.imgUrl = detail.icon;
            this.model.description = detail.description;
            this.model.site = detail.site;
            console.log(res.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    save() {
      console.log(123);
      console.log(this.model);

      this.model.id = this.$route.params.id;
      if (this.$route.params.id) {
        this.$axios
          .post("/admin/updatelink", {
            content: this.model,
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code === 0) {
              this.$message({
                message: "更新成功",
                type: "success",
              });
              setTimeout(() => {
                this.$router.push({ name: "Like" });
              }, 1400);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.$axios
          .post("/admin/addlink", {
            data: this.model,
          })
          .then((res) => {
            if (res.data.code === 0) {
              this.$message.success("添加友链成功");
              setTimeout(() => {
                this.$router.push({ name: "Like" });
              }, 1000);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
      // this.$router.push("/links/list");
      // this.$message({
      //   type: "success",
      //   message: "保存成功"
      // });
    },
    async fetch() {
      const res = await this.$http.get(`rest/links/${this.id}`);
      //this.model = res.data
      this.model = Object.assign({}, this.model, res.data);
    },
  },
  created() {
    if (this.$route.params.id) {
      //判断路由是否带有id，就是更新页面
      this.getLinkDetail();
    }
  },
};
</script>

<style lang="scss" scoped>
.laber {
  margin-top: 30px;
  margin-left: -50px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
::v-deep .el-upload-dragger {
  width: 150px;
  height: 140px;
  transition: all 0.3s;
  border-color: #dcdfe6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .el-upload__text {
    color: #a2a3a5;
    transition: all 0.3s;
  }
}
.el-input {
  width: 500px;
}
</style>
