<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <div class="title flex align-center">
      <div class="flex align-center"><span></span>网站设置</div>
    </div>
    <div class="title flex align-center"></div>
    <h2 class="tite hint-tit">基础设置</h2>
    <el-form label-width="100px">
      <el-form-item label="网站头像">
        <el-upload
          class="ploadava"
          drag
          action="http://101.43.228.111:3000/admin/upload"
          name="head_img"
          :show-file-list="false"
          :on-success="Success"
        >
          <img v-if="avatarimg" :src="avatarimg" class="avatarimg" />
          <i class="iconfont icon-jurassic_image" v-if="!iavatarimg"></i>
        </el-upload>
      </el-form-item>

      <el-form-item label="管理员名称">
        <el-input v-model="form.cover.name"></el-input>
      </el-form-item>

      <section v-for="(val, key, index) in formList" :key="index">
        <h2 class="tit hint-tit">
          首屏效果
          <el-popover placement="top" width="300" trigger="hover">
            <p>遮罩颜色请选择与图片相对应的色彩</p>
            <img src="../../assets/cover2.jpg" />
            <span slot="reference">(view)</span>
          </el-popover>
        </h2>

        <template v-for="(v, k, i) in val">
          <el-form-item v-if="k != 'value'" :label="v" :key="i">
            <template v-if="k == 'admin_avatar' || k == 'image'">
              <el-upload
                class="upload"
                drag
                action="http://101.43.228.111:3000/admin/upload"
                :on-error="uploadError"
                name="head_img"
                :on-success="handleAvatarSuccess"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="imgUrl" :src="imgUrl" class="avatar" />
                <i class="iconfont icon-jurassic_image" v-if="!imgUrl"></i>
                <div class="el-upload__text" v-if="!imgUrl">
                  上传自定义背景图片 (680*440)
                </div>
              </el-upload>
              <span class="upload-image-size" v-if="k == 'image'"
                >(1920*1080)</span
              >
            </template>

            <template v-else-if="k == 'color'">
              <el-color-picker
                v-model="form[key][k]"
                show-alpha
              ></el-color-picker>
            </template>

            <template v-else-if="k == 'menu'">
              <el-color-picker v-model="form[key][k]"></el-color-picker>
            </template>
            <template
              v-else-if="
                key == 'administrator' &&
                (k == 'comment' || k == 'subscribe' || k == 'message')
              "
            >
              <el-switch v-model="form[key][k]"></el-switch>
            </template>

            <template v-else>
              <el-input v-model="form[key][k]"></el-input>
            </template>
          </el-form-item>
        </template>
      </section>

      <el-form-item>
        <el-button class="submit" type="primary" @click="onSubmit"
          >立即保存</el-button
        >
        <span class="hint"
          ><i class="el-icon-warning"></i>信息需填写完整才能正常使用</span
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgUrl: "",
      avatarimg: "",
      formList: {
        cover: {
          value: "首屏效果",
          image: "首屏图片",
          title: "标题",
          description: "描述",
          date: "时间",
          link: "文章",
          color: "遮罩",
          menu: "menu",
        },
      },
      form: {
        cover: {
          imager: "",
          name: "",
          avatarimg: ",",
        },
      },
    };
  },
  created() {
    this.getsetting();
  },
  methods: {
    getsetting() {
      this.$axios
        .get("/admin/getsetting")
        .then((res) => {
          console.log(res.data.data[0]);
          const detail = res.data.data[0];
          console.log(detail);
          if (res.data.code == 0) {
            this.form.cover = detail;
            // this.form.cover.describe = detail.describe
            // this.form.cover.link = detail.link
            // this.form.cover.date = detail.date
            this.imgUrl = detail.backimg;
            this.avatarimg = detail.avatarimg;

            // console.log(detail.backimg);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    handleAvatarSuccess(res, file) {
      console.log(123);
      console.log(res.data);
      console.log(this.form);
      this.imgUrl = res.data;
      this.$message.success("图片上传成功");
    },
    Success(res, file) {
      console.log(123);
      console.log(res.data);
      console.log(this.form);
      this.avatarimg = res.data;
      this.$message.success("图片上传成功");
    },
    init() {
      for (let key in this.$info) {
        this.$set(this.form, key, this.$info[key]);
      }
    },
    async onSubmit() {
      this.form["cover"].imager = this.imgUrl;
      this.form["cover"].avatarimg = this.avatarimg;

      //    this.form['cover']
      console.log(this.form["cover"]);
      this.$axios
        .post("/admin/addsetting", { data: this.form["cover"] })
        .then((res) => {
          if (res.data.code == 0) {
            console.log(1234567);
            this.$message.success("修改设置成功");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form {
  max-width: 600px;
}

h2.tit {
  margin: 50px 0 30px;
  span {
    font-size: 12px;
    color: #b3b3b3;
  }
}

.el-popover {
  p {
    margin-bottom: 5px;
  }
  img {
    width: 100%;
    border-radius: 4px;
  }
}
.email-pass {
  position: absolute;
  left: -58px;
  top: 16px;
  font-size: 12px;
  color: #b3b3b3;
}
.submit:hover + .hint {
  opacity: 1;
}
.hint {
  font-size: 12px;
  color: #ff4444;
  margin-left: 10px;
  letter-spacing: 2px;
  opacity: 0;
  transition: all 0.3s;
}
h2.tite {
  margin: 20px 0 30px;
  span {
    font-size: 12px;
    color: #b3b3b3;
  }
}
.upload {
  margin-left: -40px;
  &:first-of-type {
    margin-right: 20px;
  }
  &:hover {
    ::v-deep .el-upload-dragger {
      border-color: #409eff;
      .iconfont,
      .el-upload__text {
        color: #409eff;
      }
    }
  }
  .avatar {
    width: 100%;
    height: 100%;
  }
  .iconfont {
    color: #a2a3a5;
    font-size: 34px;
    margin-bottom: 10px;
    transition: all 0.3s;
  }
  ::v-deep .el-upload-dragger {
    width: 400px;
    height: 240px;
    transition: all 0.3s;
    border-color: #dcdfe6;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .el-upload__text {
      color: #a2a3a5;
      transition: all 0.3s;
    }
  }
}

.ploadava {
  ::v-deep .el-upload-dragger {
    width: 130px;
    height: 130px;
    transition: all 0.3s;
    border-color: #dcdfe6;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 50%;
    .avatarimg {
      width: 135%;
      height: 135%;
    }

    ::v-deep .el-upload-dragger {
      width: 150px;
      height: 240px;
      transition: all 0.3s;
      border-color: #dcdfe6;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .el-upload__text {
        color: #a2a3a5;
        transition: all 0.3s;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .cover {
    ::v-deep .el-upload {
      width: 100%;
    }
  }
  .hint {
    opacity: 1;
    display: block;
  }
  ::v-deep .el-form-item {
    label {
      width: 90px !important;
    }
    .el-form-item__content {
      margin-left: 90px !important;
    }
  }
}
</style>