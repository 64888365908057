<template>
  <div>
    <div class="wrapper">
      <div class="title flex align-center">
        <div class="flex align-center"><span></span>文章列表 ({{ total }})</div>
      </div>
      <div class="article">
        <el-button type="primary" class="addBtn" @click="handleAdd">
          新增+</el-button
        >
        <el-table :data="articleList" border stripe class="table">
          <!-- 第一列 -->
          <el-table-column prop="title" label="标题" width="200">
          </el-table-column>

          <el-table-column prop="title" label="封面" width="145">
            <template slot-scope="scope">
              <img
                :src="scope.row.imgUrl"
                alt=""
                style="
                  width: 120px;
                  height: 73px;
                  border-radius: 3.5px;
                  margin-top: 6px;
                "
              />
            </template>
          </el-table-column>
          <!-- 第二列 -->
          <el-table-column label="音乐名称" width="120">
            <template slot-scope="scope">
              <span>{{ scope.row.musicName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="浏览" width="110">
            <template slot-scope="scope">
              <span>{{ scope.row.visitsNum }}浏览</span>
            </template>
          </el-table-column>
          <el-table-column label="点赞" width="110">
            <template slot-scope="scope">
              <span>{{ scope.row.likeNum }}点赞</span>
            </template>
          </el-table-column>

          <!-- 第二列 -->
          <el-table-column label="日期" width="200">
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span>{{ scope.row.createdAt }}</span>
            </template>
          </el-table-column>
          <!-- 第三列 -->

          <!-- 第四列 -->
          <el-table-column label="操作" width="135">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                icon="el-icon-edit"
                @click="handleEdit(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="small"
                icon="el-icon-delete"
                @click="handleDelect(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="blocks flex flex-wrap">
        <div class="block info flex align-center justify-center">
          <div class="cover"><img src="@/assets/cover2.jpg" alt="" /></div>
          <span>Hello，我是南烟</span>
        </div>
        <div class="block flex align-center justify-center flex-column article">
          <div class="title">文章</div>
          <div><span>100</span>篇</div>
          <div class="tips">二天前发布了一篇文章，继续努力哦！</div>
        </div>
        <div class="block comment flex align-center justify-center flex-column">
          <div class="title">评论</div>
          <div><span>100</span>条</div>
          <div class="tips">感谢陌生人的留言！</div>
        </div>
        <div
          class="block flex align-center justify-center flex-column peopleNum"
        >
          <div class="title">说说</div>
          <div><span>40</span>条</div>
          <div class="tips">一些闲言碎语</div>
        </div>
        <div
          class="block flex align-center justify-center flex-column peopleNum"
        >
          <div class="title">图文</div>
          <div><span>46</span>篇</div>
          <div class="tips">微小而美好的故事</div>
        </div>
        <div
          class="block flex align-center justify-center flex-column peopleNum"
        >
          <div class="title">友链</div>
          <div><span>50</span>条</div>
          <div class="tips">小天地</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      articleList: [],
    };
  },
  methods: {
    handleLook(row) {
      let id = row.id;
      window.open("#/detail/" + id);
    },
    handleAdd() {
      this.$router.push({ name: "addart" });
    },
    handleEdit(row) {
      let id = row.id;
      this.$router.push({ path: `/addart/${id}` });
    },
    handleDelect(row) {
      let id = row.id;
      this.$confirm("此操作将永久删除该⽂章, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //请求删除api todo
          this.$axios
            .post("/admin/deleteart", { article_id: id })
            .then((res) => {
              console.log(res);

              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: `${id}文章删除成功`,
                });
                setTimeout(() => {
                  location.reload();
                }, 1400);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getMyBlogList() {
      this.$axios
        .get("/admin/myList")
        .then((res) => {
          console.log(res);

          if (res.data.code == 0) {
            console.log(res.data.data);
            this.articleList = res.data.data;

            console.log(6666);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  created() {
    this.getMyBlogList();
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
}
.title {
  display: flex;
  align-items: center;
  color: #2c80f7;
  font-size: 18px;
  height: 26px;
  div {
    display: flex;
    align-items: center;
    height: 100%;
    span {
      width: 2px;
      height: 100%;
      background: #3c8af7;
      margin-right: 20px;
    }
  }
}
.article {
  .table {
    top: 30px;
    ::v-deep td {
      // 设置elementui的行高
      padding: 0;
      height: 75px;
      line-height: 30px;
    }
  }
  .addBtn {
    float: right;
    margin-top: -40px;
  }
}
.el-table {
  .cell {
    text-align: center;
  }
}
.column {
  height: 10px;
}
.blocks {
  margin-top: 10%;
  display: flex;
  flex-wrap: wrap;

  // 卡片文章
  .block {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1 0 31%;
    height: 300px;
    margin-right: 2%;
    margin-top: 4%;
    background-color: #ffffff;
    border-radius: 10px;
    position: relative;
    &:nth-of-type(0),
    &:nth-of-type(2) &:nth-of-type(3) {
      margin-bottom: 30px;
      margin-top: 30px;
    }

    .cover img {
      position: absolute;
      left: 1.5px;
      top: 2px;
      margin-top: 0px;
      width: 322px;
      height: 200px;
      border-radius: 5px;
    }
  }
}
</style>
